import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Counter from "yet-another-react-lightbox/plugins/counter";
import {Close, NavigateBefore, NavigateNext,} from "@mui/icons-material";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";
import logGalleryEvent from "./logGalleryEvent";
import {galleryConfig} from "../../Resources/galleryConfig";

const galleryHash = "#gallery";

export function GalleryLightbox(props: {
    index: number
}) {
    const [open, setOpen] = useState(false);

    let currentSlide = -1;

    const location = useLocation();
    const navigate = useNavigate();
    const zoomRef = React.useRef(null);

    const openedByClick = props.index >= 0;

    useEffect(() => setOpen(location.hash === galleryHash), [location.hash]);

    return (
        <Lightbox plugins={[Zoom, Counter]}
            zoom={{ref: zoomRef}}
            counter={{
                container: {
                    style: {
                        fontFamily: "var(--sans-serif-font-family)",
                                fontSize: "0.875rem",
                                opacity: 0.54
                        }
                }
            }}
            open={open}
            close={() => {
                if (openedByClick) {
                    navigate(-1);
                } else {
                    navigate("", {replace: true});
                }
            }}
            index={props.index >= 0 ? props.index : 0}
            slides={galleryConfig.photos.map((photo) => ({
                src: `${galleryConfig.path}/2x/${photo.filename}`,
                srcSet: [
                    {
                        src: `${galleryConfig.path}/1x/${photo.filename}`,
                        width: (photo.landscape ?
                            galleryConfig.dimensions.height :
                            galleryConfig.dimensions.width) / 2,
                        height: (photo.landscape ?
                            galleryConfig.dimensions.width :
                            galleryConfig.dimensions.height) / 2
                    },
                    {
                        src: `${galleryConfig.path}/2x/${photo.filename}`,
                        width: photo.landscape ?
                            galleryConfig.dimensions.height :
                            galleryConfig.dimensions.width,
                        height: photo.landscape ?
                            galleryConfig.dimensions.width :
                            galleryConfig.dimensions.height
                    }
                ]
            }))}
            render={{
                iconNext: () => <NavigateNext fontSize="large"/>,
                iconPrev: () => <NavigateBefore fontSize="large"/>,
                iconClose: () => <Close fontSize="large"/>,
                buttonZoom: () => <></>
            }}
            controller={{
                closeOnPullDown: true,
                closeOnBackdropClick: true
            }}
            on={{
                view: ({index}) => {
                    currentSlide = index;
                    logGalleryEvent("view_photo", galleryConfig.photos[index].filename, index)
                },
                zoom: ({zoom}) =>
                    logGalleryEvent("zoom_photo", zoom.toString(), currentSlide),
                entered: () =>
                    logGalleryEvent("open_lightbox", openedByClick ? "click" : "route"),
                exiting: () =>
                    logGalleryEvent("close_lightbox", galleryConfig.photos[currentSlide].filename, currentSlide)
            }}
        />
    );
}

export function useOpenGalleryLightbox()
{
    const navigate = useNavigate();

    return () => navigate(galleryHash);
}