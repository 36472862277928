import {createContext, useContext, useEffect, useState} from "react";
import CommentingApiClient from "../CommentingApiClient";
import {appConfig} from "../appConfig";
import {useAuthorToken} from "./AuthorTokenContext";
import HttpCommentingApiClient from "../HttpCommentingApiClient";
import OfflineCommentingApiClient from "../OfflineCommentingApiClient";

const CommentingApiClientContext = createContext<CommentingApiClient | null>(null);

export function CommentingApiClientProvider(props: {
    children: any
}) {
    const [apiClient, setApiClient] = useState<CommentingApiClient | null>(null);
    const authorToken = useAuthorToken();

    useEffect(() => {
        if (!appConfig.commentingApiUri) {
            setApiClient(new OfflineCommentingApiClient());
        } else if (authorToken) {
            setApiClient(new HttpCommentingApiClient(appConfig.commentingApiUri, authorToken));
        } else{
            setApiClient(null);
        }
    }, [authorToken]);

    return (
        <CommentingApiClientContext.Provider value={apiClient}>
            {props.children}
        </CommentingApiClientContext.Provider>
    );
}

export function useCommentingApiClient() {
    return useContext(CommentingApiClientContext);
}