import "./Comments.scss";
import {Box} from "@mui/material";
import React from "react";
import CommentDto from "../../../CommentDto";
import CommentFormBox from "./CommentFormBox";
import CommentList from "./CommentList";
import {useTextResources} from "../../../Contexts/TextResourcesContext";
import {useNotifier} from "../../../Contexts/NotifierContext";
import {useCommentsState} from "../../../Contexts/CommentsStateContext";

export default function CommentsSection(props: {
    locale: string
}) {
    const resources = useTextResources().giving.comments;
    const notifier = useNotifier();
    const commentsState = useCommentsState();

    const boxRef = React.useRef<HTMLDivElement>(null);

    return (
        <Box component="section" id="comments" ref={boxRef}>
            <h2>{resources.title}</h2>

            <CommentFormBox
                enabled={!commentsState?.isReadOnly() ?? false}
                previouslySubmitted={commentsState?.previouslySubmitted() ?? false}
                open={() => window.setTimeout(() => boxRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "start"
                }), 500)}
                success={(newComment: CommentDto) => {
                    notifier.success(resources.form.success);
                    commentsState?.addComment(newComment);
                }}
            />

            <CommentList locale={props.locale}/>
        </Box>
    );
}