import {Badge, BottomNavigationAction, Paper, setRef} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import IntroIcon from "@mui/icons-material/Event";
import GalleryIcon from "@mui/icons-material/Collections";
import DirectionsIcon from "@mui/icons-material/LocationOn";
import RegistryIcon from "@mui/icons-material/Favorite";
import React, {useRef} from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import config from "../../config.scss";
import "./AppNavigation.scss";
import NavigationObserver from "./NavigationObserver";
import {AppSection} from "../../AppSection";
import logEvent from "../../logEvent";
import {useCommentsState} from "../../Contexts/CommentsStateContext";

export default function AppNavigation() {
    const [selectedSection, setSelectedSection] = React.useState(AppSection.cover);
    const commentsState = useCommentsState();
    const sectionRef = useRef(null);

    const navigateTo = (id: string) => {
        setRef(sectionRef, document.getElementById(id));
        setSelectedSection(AppSection[id as keyof typeof AppSection]);

        if (sectionRef.current !== null) {
            (sectionRef.current as Element)?.scrollIntoView();
        }
    }

    return (
        <Paper className="app-navigation" sx={{
            width: config.containerWidth
        }} elevation={3}>
            <BottomNavigation
                value={selectedSection}
                onChange={(event, newValue) => {
                    navigateTo(newValue);
                    logEvent("navigation", "section_selected", newValue);
                }}
                showLabels={false}
                sx={{backgroundColor: config.textBackgroundColor}}
            >
                <BottomNavigationAction label="" value={AppSection.cover} icon={<HomeIcon/>} aria-label="cover" />
                <BottomNavigationAction label="" value={AppSection.invitation} icon={<IntroIcon/>} aria-label="invitation" />
                <BottomNavigationAction label="" value={AppSection.gallery} icon={<GalleryIcon/>} aria-label="gallery" />
                <BottomNavigationAction label="" value={AppSection.directions} icon={<DirectionsIcon/>} aria-label="directions" />
                <BottomNavigationAction
                    label=""
                    value={AppSection.giving}
                    icon={
                        <Badge
                            badgeContent={commentsState?.countUnseen() ?? 0}
                            color="primary"
                            variant="dot"
                        >
                            <RegistryIcon/>
                        </Badge>
                    }
                    aria-label="giving"
                />
            </BottomNavigation>
            <NavigationObserver
                watchIds={Object.values(AppSection)}
                threshold={0.4}
                sectionEntered={sectionId => {
                    const section = AppSection[sectionId as keyof typeof AppSection];

                    if (section === selectedSection) {
                        return;
                    }

                    setSelectedSection(section);
                    logEvent("navigation", "section_entered", sectionId);
                }}
            />
        </Paper>
    );
}