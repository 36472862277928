import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import ReactGA from "react-ga4";
import { AuthorTokenProvider } from './Contexts/AuthorTokenContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <AuthorTokenProvider>
                <App />
            </AuthorTokenProvider>
        </BrowserRouter>
    </React.StrictMode>
);

reportWebVitals(({ id, name, value }) => {
    ReactGA.send({
        hitType: "event",
        eventCategory: "web_vitals",
        eventAction: name,
        eventValue: Math.round(name === "CLS" ? value * 1000 : value),
        eventLabel: id,
        nonInteraction: true
    });
});
