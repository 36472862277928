import {createTheme, ThemeProvider} from "@mui/material";
import config from "./config.scss";
import {koKR} from "@mui/x-date-pickers/locales";
import {enUS} from "@mui/material/locale";
import {ReactNode} from "react";

export default function AppThemeProvider(props: {
    children: ReactNode,
    lang: string
}) {
    return (
        <ThemeProvider theme={
            createTheme(
                {
                    palette: {
                        primary: {
                            main: config.primaryColor,
                            contrastText: "rgba(255, 255, 255, 1)"
                        },
                        secondary: {
                            main: config.textBackgroundColor,
                            contrastText: config.darkTextColor
                        },
                    },
                    typography: {
                        fontFamily: 'var(--serif-font-family)'
                    }
                },
                props.lang === 'ko' ? koKR : enUS)
        }>
            {props.children}
        </ThemeProvider>
    );
}