import CommentDto from "./CommentDto";
import CommentingApiClient from "./CommentingApiClient";
import {comments} from "./Resources/comments";

export default class OfflineCommentingApiClient implements CommentingApiClient {
    private readonly data: CommentDto[];

    public constructor() {
        this.data = comments.sort((a, b) => b.timestamp - a.timestamp);
    }

    public async getComments() {
        return this.data;
    }

    public postComment(authorName: string, commentText: string): Promise<Response> {
        throw Error("POST unsupported");
    }

    public deleteComment(id: string): Promise<Response> {
        throw Error("DELETE unsupported");
    }

    public isReadOnly(): boolean {
        return true;
    }
}