import ShowBankInformationButton from "./ShowBankInformationButton";
import KakaoPayButton from "./KakaoPayButton";
import React from "react";
import {useTextResources} from "../../../Contexts/TextResourcesContext";

export default function KoreanPaymentOptions(props: {
    party: "groom" | "bride"
}) {
    const resources = useTextResources().giving.registry[props.party];

    return (
        <div>
            <h3>{resources.title}</h3>

            <ul className="payment-options">
                <li>
                    <ShowBankInformationButton party={props.party}/>
                </li>

                <li>
                    <KakaoPayButton party={props.party}/>
                </li>
            </ul>
        </div>
    );

}