import CommentDto from "../../../CommentDto";
import {Button} from "@mui/material";
import logGivingEvent from "../logGivingEvent";
import logGalleryEvent from "../../Gallery/logGalleryEvent";
import React from "react";
import {useTextResources} from "../../../Contexts/TextResourcesContext";
import {useCommentsState} from "../../../Contexts/CommentsStateContext";
import {useNotifier} from "../../../Contexts/NotifierContext";

export function DeleteCommentButton(props: {
    comment: CommentDto,
    processing: () => void,
    success: () => void,
    error: () => void
}) {
    const resources = useTextResources().giving.comments;
    const commentsState = useCommentsState();
    const notifier = useNotifier();

    return (
        <div className="options">
            <Button
                className="delete-button"
                variant="text"
                size="small"
                disabled={!commentsState}
                onClick={() => {
                    if (!commentsState) {
                        return;
                    }

                    logGivingEvent("comment_delete_clicked", props.comment.id);

                    if (!window.confirm(resources.delete.confirm)) {
                        logGalleryEvent("comment_delete_cancelled", props.comment.id);
                        return;
                    }

                    logGalleryEvent("comment_delete_confirmed", props.comment.id);

                    props.processing();

                    commentsState.deleteComment(props.comment.id)
                        .then(response => {
                            if (response.ok) {
                                notifier.success(resources.delete.success);
                                props.success();
                            } else {
                                console.error(response);
                                notifier.error(resources.delete.error);
                                props.error();
                            }
                        })
                        .catch(error => {
                            console.error(error);
                            notifier.error(resources.delete.error);
                            props.error();
                        });
                }}
            >
                {resources.delete.button}
            </Button>
        </div>
    );
}