import {DirectionsConfig} from "../Components/Directions/DirectionsConfig";

export const directionsConfig: DirectionsConfig = {
    map: {
        center: {
            latitude: 37.50978,
            longitude: 126.993132
        },
        marker: {
            latitude: 37.50978,
            longitude: 126.993132
        }
    },
    androidLink: "geo:?q=반포한강공원+2주차장",
    iosLink: "nmap://place?id=1235631099&name=반포한강공원+2주차장",
    webLink: "https://naver.me/xcKga0JJ"
}