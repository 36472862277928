import React, {useEffect} from 'react';
import './App.scss';
import Cover from "./Components/Cover/Cover";
import InvitationSection from "./Components/Invitation/InvitationSection";
import GallerySection from "./Components/Gallery/GallerySection";
import DirectionsSection from "./Components/Directions/DirectionsSection";
import GivingSection from "./Components/Giving/GivingSection";
import AppNavigation from "./Components/AppNavigation/AppNavigation";
import {Container} from "@mui/material";
import {isAndroid, isChrome, isIOS, isMobile, isMobileSafari} from "react-device-detect";
import ReactGA from 'react-ga4';
import {useLocation} from "react-router-dom";
import logEvent from "./logEvent";
import {appConfig} from "./appConfig";
import {LanguageToggle} from "./Components/LanguageToggle";
import AppThemeProvider from "./AppThemeProvider";
import {useAuthorToken} from "./Contexts/AuthorTokenContext";
import {CommentingApiClientProvider} from "./Contexts/CommentingApiClientContext";
import {NotifierProvider} from "./Contexts/NotifierContext";
import {TextResourcesProvider} from "./Contexts/TextResourcesContext";
import CommentsStateProvider from './Contexts/CommentsStateContext';

const storedLang = localStorage.getItem("lang") as "ko" | "en" | null;
const browserLang = navigator.language.startsWith("en") ? "en" : "ko";
const resolvedLang = storedLang ?? browserLang;

if (storedLang) {
    setLanguage(storedLang, "language_restored");
} else {
    setLanguage(browserLang, "language_inferred");
}

export default function App() {
    const [lang, setLang] = React.useState<"ko" | "en">(resolvedLang);

    const location = useLocation();
    const authorToken = useAuthorToken();

    useEffect(() => {
        if (appConfig.gaTrackingId === undefined) {
            return;
        }

        ReactGA.initialize([{
            trackingId: appConfig.gaTrackingId,
            gtagOptions: {
                userId: authorToken
            }
        }]);
    }, [authorToken]);

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: location.pathname + location.search
        });
    }, [location.pathname, location.search]);

    useEffect(() => setLanguage(lang, "language_set"), [lang]);

    return (
        <AppThemeProvider lang={lang}>
            <TextResourcesProvider lang={lang}>
                <NotifierProvider>
                    <CommentingApiClientProvider>
                        <CommentsStateProvider>
                            <Container
                                maxWidth="sm"
                                disableGutters={true}
                                className={(
                                    (isMobile && isChrome) || isMobileSafari ? "mobile " : "") +
                                    (isIOS ? "mobile-ios " : "") +
                                    (isAndroid ? "mobile-android" : "")}
                                id="invitation-container"
                            >
                                <AppNavigation/>

                                <Cover/>
                                <InvitationSection/>
                                <GallerySection/>
                                <DirectionsSection/>
                                <GivingSection/>
                            </Container>

                            <LanguageToggle
                                lang={lang}
                                change={lang => {
                                    setLang(lang);
                                    logEvent("page", "language_toggle", lang);
                                }}
                            />
                        </CommentsStateProvider>
                    </CommentingApiClientProvider>
                </NotifierProvider>
            </TextResourcesProvider>
        </AppThemeProvider>
    );
}

function setLanguage(lang: "ko" | "en", eventName: string) {
    localStorage.setItem("lang", lang);
    document.documentElement.setAttribute("lang", lang);
    logEvent("page", eventName, lang);
}