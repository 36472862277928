import {useEffect} from "react";

export default function NavigationObserver(props: {
    watchIds: string[],
    threshold: number,
    sectionEntered: (sectionId: string) => void
}) {
    useEffect(() => {
        const sections = (props.watchIds
            .map(id => document.getElementById(id))
            .filter(section => section !== null) as HTMLElement[])
            .sort((a, b) =>
                (a?.getBoundingClientRect().top ?? 0) - (b?.getBoundingClientRect().top ?? 0));

        let timeout: number | undefined = undefined

        const listener = () => {
            if (timeout !== undefined) {
                return;
            }

            timeout = window.setTimeout(() => {
                const lastSection = sections
                    .filter(section =>
                        section.getBoundingClientRect().top < window.innerHeight * (1 - props.threshold))
                    .pop();

                props.sectionEntered(lastSection?.id ?? "");
                timeout = undefined;
            }, 100);
        };

        window.addEventListener('scroll', listener, {passive: true});

        return () => {
            window.removeEventListener('scroll', listener);

            if (timeout !== undefined) {
                window.clearTimeout(timeout);
            }
        }
    }, [props]);

    return (<></>);
}