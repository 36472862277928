import {useTextConfig, useTextResources} from "../../Contexts/TextResourcesContext";

export function SubwayDirections() {
    const resources = useTextResources().directions;
    const config = useTextConfig().directions.subwayDirections;

    return <>
        <h3>{resources.subwayDirectionsTitle}</h3>
        <ul className="directions-section">
            {
                config.stops.map((stop, index) =>
                    <li key={index}>
                        <div>{stop.name}{
                            stop.distance !== undefined ?
                                <span className="distance">{stop.distance}</span> :
                                <></>
                        }</div>
                        <ul className="subway-routes">
                            {
                                stop.lines.map((line, index) =>
                                    <li className={line.className} key={index}>{line.name}</li>)
                            }
                        </ul>
                        <div>{stop.details}</div>
                    </li>
                )
            }
        </ul>
    </>;
}

