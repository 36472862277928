import {useTextResources} from "../../Contexts/TextResourcesContext";
import React from "react";

export default function Calendar(props: { eventDate: Date }) {
    const locale = useTextResources().locale;

    const startDayOfWeek =
        new Date(props.eventDate.getFullYear(), props.eventDate.getMonth(), 1).getDay();
    const lastDayOfMonth =
        new Date(props.eventDate.getFullYear(), props.eventDate.getMonth() + 1, 0).getDate();
    const eventDay = props.eventDate.getDate();

    let weeks: number[][] = [];

    for (let i = -startDayOfWeek + 1; i <= lastDayOfMonth; i += 7) {
        weeks.push([i, i + 1, i + 2, i + 3, i + 4, i + 5, i + 6]);
    }

    return (
        <div className="calendar">
            <h3>{props.eventDate.toLocaleString(locale, {month: "long", year: "numeric"})}</h3>

            <div className="calendar-header">
                {
                    getNamesOfDaysOfWeek(locale)
                        .map((day, index) => <span key={index}>{day}</span>)
                }
            </div>

            {
                weeks.map((week, index) => (
                    <div key={index}>
                        {
                            week.map((day, index) => (
                                <span
                                    key={index}
                                    className={day === eventDay ? "event-date" : ""}
                                >
                                    {day > 0 && day <= lastDayOfMonth ? day : ""}
                                </span>
                            ))
                        }
                    </div>
                ))
            }
        </div>
    )
}

function getNamesOfDaysOfWeek(locale: string) {
    const weekdays = new Array(7);

    for (let i = 0; i < 7; i++) {
        const currentDate = new Date(Date.UTC(2023, 0, i + 1));
        weekdays.push(new Intl.DateTimeFormat(locale, {
            weekday: "narrow",
            timeZone: "UTC"
        }).format(currentDate));
    }

    return weekdays;
}