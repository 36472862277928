import TextConfig from "./TextConfig";

export const textConfigEn: TextConfig = {
    cover: {
        groomFullName: "Matthew Suhocki",
        brideFullName: "Sohyun Kim",
        weddingDate: "Saturday, Sept. 2, 2023",
        weddingTime: "5:00 P.M.",
        weddingLocation: "Marina Park at Banpo Hangang Park"
    },

    invitation: {
        message:
            "Matthew, son of Peter and Susan Suhocki,\n" +
            "and\n" +
            "Sohyun, daughter of Jaeyong and Jiwon Kim,\n" +
            "request the honor of your presence\n" +
            "at the celebration of our marriage.\n\n" +
            "Saturday, September 2, 2023\n" +
            "Five o'clock in the afternoon\n\n" +
            "Marina Park Wedding Hall\n" +
            "at Banpo Hangang Park\n\n" +
            "Dinner and reception to follow.",
        groom: {
            fatherName: "Peter",
            motherName: "Susan Suhocki",
            relationship: "son",
            name: "Matthew"
        },
        bride: {
            fatherName: "Jaeyong",
            motherName: "Jiwon Kim",
            relationship: "daughter",
            name: "Sohyun"
        },
        eventDate: "2023-09-02"
    },

    directions: {
        locationName: "2F, Marina Park at Banpo Hangang Park",
        fullAddress: "2085-18, Olympic-daero, Seocho-gu, Seoul",
        navigationAddress: "서울시 서초구 올림픽대로 2085-18",
        drivingDirections: {
            notice: "The parking lot might be crowded, so we recommend taking public transportation if possible.",
            directions: [
                {
                    title: "Navigation",
                    note: "Search for ‟Banpo Hangang Park Parking Lot” or ‟반포한강공원 2주차장”.",
                    turns: []
                }
            ],
            extendedDirections: [
                {
                    title: "Olympic-daero (toward the airport)",
                    turns: [
                        "Go past Banpro Bridge",
                        "Got off at the Banpo Hangang Park exit"
                    ]
                },
                {
                    title: "Olympic-daero (toward Jamsil)",
                    turns: [
                        "Get off before Dongjak Bridge and head toward the Isu Overpass",
                        "Take a left at the Seongmo Hospital intersection",
                        "Make a 9 o'clock turn at the Jamsu bridge roundabout toward the floating islands"
                    ]
                },
                {
                    title: "From Yongsan toward Gangnam",
                    turns: [
                        "Cross to the south side of Jamsu Bridge",
                        "Make a 3 o'clock turn toward the floating islands"
                    ]
                }
            ]
        },
        subwayDirections: {
            stops: [
                {
                    name: "Express Bus Terminal Station",
                    lines: [
                        {
                            name: "Line 3",
                            className: "subway-line-3"
                        },
                        {
                            name: "Line 7",
                            className: "subway-line-7"
                        },
                        {
                            name: "Line 9",
                            className: "subway-line-9"
                        }
                    ],
                    details: "There will be a free shuttle bus departing every 10-15 minutes from in front of exit 6."
                },
                {
                    name: "Sinbanpo Station",
                    distance: "10 minute walk",
                    lines: [
                        {
                            name: "Line 9",
                            className: "subway-line-9"
                        }
                    ],

                }
            ]
        },
        busDirections: {
            stops: [
                {
                    name: "Banpo Hangang Park, Sebitseom",
                    distance: "5 minute walk",
                    lines: [
                        {
                            name: "405",
                            className: "blue-bus"
                        },
                        {
                            name: "740",
                            className: "blue-bus"
                        }
                    ]
                },
                {
                    name: "South Side of Banpo Bridge",
                    distance: "20 minute walk",
                    lines: [
                        {
                            name: "143",
                            className: "blue-bus"
                        },
                        {
                            name: "401",
                            className: "blue-bus"
                        },
                        {
                            name: "406",
                            className: "blue-bus"
                        }
                    ]
                },
            ]
        }
    },

    registry: {
        groom: {
            bankTransfer: {
                bankName: "KB Kookmin Bank",
                accountNumber: "080802-04-096261",
                accountName: "SUHOCKIMAT"
            },
            kakaoPayLink: "https://kko.to/4e32kqR-lB"
        },
        bride: {
            bankTransfer: {
                bankName: "Hana Bank",
                accountNumber: "869-910251-66307",
                accountName: "김소현"
            },
            kakaoPayLink: "https://kko.to/3nndnjtm3Q"
        },
        overseas: [
            {
                title: "Amazon Registry",
                url: "https://www.amazon.com/wedding/share/suhocki-kim"
            },
            {
                title: "Venmo",
                url: "https://venmo.com/suhock"
            }
        ]
    }
};