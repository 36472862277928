import {createContext, ReactNode, useContext} from "react";
import {v4 as uuidv4, validate as validateUuid} from "uuid";

const AuthorTokenContext = createContext<string>("");

export function AuthorTokenProvider(props: {children: ReactNode}) {
    return (
        <AuthorTokenContext.Provider value={getAuthorToken()}>
            {props.children}
        </AuthorTokenContext.Provider>
    );
}

export function useAuthorToken(): string {
    return useContext(AuthorTokenContext);
}

function getAuthorToken(): string {
    let storedAuthorToken = localStorage.getItem("authorToken");

    if (storedAuthorToken === null || !validateUuid(storedAuthorToken)) {
        storedAuthorToken = uuidv4();
        localStorage.setItem("authorToken", storedAuthorToken);
    }

    return storedAuthorToken;
}