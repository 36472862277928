import TextResources from "./TextResources";

export const textResourcesKo: TextResources = {
    locale: "ko-KR",

    pageTitle: "Matthew & Sohyun",

    invitation: {
        title: "결혼합니다"
    },

    gallery: {
        title: "갤러리",
        seeMoreButton: "더 보기",
        instagramButton: "우리 팔로우해 보세요"
    },

    directions: {
        title: "오시는 길",
        navigation: {
            androidLinkName: "내비 시작",
            iosLinkName: "내비 시작(네이버)",
            webLinkName: "지도 보기"
        },
        drivingDirectionsTitle: "자가용",
        subwayDirectionsTitle: "지하철",
        busDirectionsTitle: "버스",
        seeMoreButton: "자세히 보기",
        addressCopied: "주소가 복사되었습니다."
    },

    giving: {
        registry: {
            title: "축하 마음 전하시는 곳",
            introduction: "",
            bankAccount: {
                openButton: "계좌번호 보기",
                copyButton: "복사하기",
                closeButton: "닫기",
                copiedNotice: "계좌번호가 복사되었습니다.",
                bankName: "은행",
                accountNumber: "계좌번호",
                accountOwner: "예금주"
            },
            kakaoPayButton: "카카오페이",
            groom: {
                title: "신랑측",
                dialogTitle: "신랑측 계좌",
            },
            bride: {
                title: "신부측",
                dialogTitle: "신부측 계좌",
            },
            overseasTitle: "해외용"
        },
        comments: {
            title: "축하 말씀 남기는 곳",

            submittedIntro: "축하 말씀 작성해 주셔서 감사합니다!",
            commentingDisabled: "축하 말씀 많이 작성해 주셔서 감사합니다! 새 메시지 작성이 종료되었습니다.",
            openButton: "또 작성하기",
            seeMoreButton: "남은 {0}개 중 더 보기",

            form: {
                authorLabel: "이름",
                textLabel: "메시지",
                submitButton: "등록하기",
                cancelButton: "취소",

                success: "축하 말씀 작성해 주셔서 감사합니다!",

                errors: {
                    authorRequired: "이름을 입력해 주세요.",
                    textRequired: "메시지를 입력해 주세요.",
                    general: "메시지 등록에 실패했습니다. 다시 시도해 주세요."
                }
            },

            delete: {
                button: "삭제하기",
                success: "메시지가 삭제되었습니다.",
                error: "메시지 삭제에 실패했습니다. 다시 시도해 주세요.",
                confirm: "메시지를 삭제하시겠습니까?",
            }
        }
    }
};