import {Button, Collapse} from "@mui/material";
import {useState} from "react";
import {ExpandMore} from "@mui/icons-material";
import logDirectionsEvent from "./logDirectionsEvent";
import {useTextConfig, useTextResources} from "../../Contexts/TextResourcesContext";
import {DrivingDirectionsRoute} from "./DirectionsTextConfig";

export default function DrivingDirections() {
    const [open, setOpen] = useState(false);

    const resources = useTextResources().directions;
    const config = useTextConfig().directions.drivingDirections;
    return <>
        <h3>{resources.drivingDirectionsTitle}</h3>

        {
            config.notice ?
                <div>{config.notice}</div> :
                <></>
        }

        <DrivingDirectionsTurnList resources={config.directions}/>

        <Collapse in={open} timeout="auto" unmountOnExit>
            <DrivingDirectionsTurnList resources={config.extendedDirections}/>
        </Collapse>

        <div style={{textAlign: "center"}}>
            <Button
                onClick={() => {
                    setOpen(!open);
                    logDirectionsEvent("driving_see_more_clicked")
                }}
                style={{
                    display: open ? "none" : "inline-flex",
                    marginTop: "16px",
                    padding: "0",
                    fontWeight: "500"
                }}
            >
                {resources.seeMoreButton}
                <ExpandMore/>
            </Button>
        </div>
    </>;
}

function DrivingDirectionsTurnList(props: { resources: DrivingDirectionsRoute[] }) {
    return <ul className="directions-section">
        {
            props.resources.map((resource, index) => {
                return <li key={index}>
                    <div>{resource.title}</div>

                    {
                        resource.note ? <div className="directions-note">{resource.note}</div> : <></>
                    }

                    {
                        resource.turns.length > 0 ?
                            <ul className="car-routes">
                                {
                                    resource.turns.map((turn, index) => {
                                        return <li key={index}>{turn}</li>;
                                    })
                                }
                            </ul> :
                            <></>
                    }
                </li>
            })
        }
    </ul>;
}
