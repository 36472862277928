import './Cover.scss';
import {Box} from "@mui/material";
import {useEffect} from "react";
import {AppSection} from "../../AppSection";
import {useTextConfig} from "../../Contexts/TextResourcesContext";

export default function Cover() {
    const config = useTextConfig().cover;

    useEffect(() => {
        document.documentElement.style.setProperty(
            '--window-height',
            document.documentElement.clientHeight + "px");
    }, []);

    return (
        <Box component="header" className="section" id={AppSection.cover}>
            <div className="banner-image">
                <img
                    src="/assets/cover-1x.jpg"
                    srcSet={
                        "/assets/cover-1x.jpg 1x, " +
                        "/assets/cover-2x.jpg 2x, " +
                        "/assets/cover-3x.jpg 3x"
                    }
                    alt="커버사진" />
            </div>

            <h1 className="bride-and-groom">
                <span className="groom">{config.groomFullName}</span>
                <span className="bride">{config.brideFullName}</span>
            </h1>

            <div className="schedule">
                <span className="date">{config.weddingDate}</span>
                <span className="time">{config.weddingTime}</span>
            </div>

            <div className="location">
                <span>{config.weddingLocation}</span>
            </div>
        </Box>
    );
}