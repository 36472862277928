import './DirectionsSection.scss';
import {CopyToClipboard} from "react-copy-to-clipboard";
import DrivingDirections from "./DrivingDirections";
import {SubwayDirections} from "./SubwayDirections";
import {BusDirections} from "./BusDirections";
import {NavigationLauncher} from "./NavigationLauncher";
import React from "react";
import {NaverMap} from "./NaverMap";
import {AppSection} from "../../AppSection";
import {ContentCopy} from "@mui/icons-material";
import {useTextConfig, useTextResources} from "../../Contexts/TextResourcesContext";
import {useNotifier} from "../../Contexts/NotifierContext";
import {directionsConfig} from "../../Resources/directionsConfig";

export default function DirectionsSection() {
    const resources = useTextResources().directions;
    const config = useTextConfig().directions;

    const notifier = useNotifier();

    return (
        <section className="section" id={AppSection.directions}>
            <h2>{resources.title}</h2>

            <NaverMap
                center={directionsConfig.map.center}
                marker={directionsConfig.map.marker}
            />
            <NavigationLauncher/>

            <div className="directions-location">
                <div>{config.locationName}</div>

                <CopyToClipboard
                    text={config.navigationAddress}
                    onCopy={() => notifier.success(resources.addressCopied)}
                >
                    <div style={{cursor: "pointer"}}>
                        {config.fullAddress}
                        <ContentCopy
                            style={{
                                position: "relative",
                                top: "2px",
                                fontSize: "16px",
                                marginLeft: "8px",
                                verticalAlign: "baseline"
                            }}
                        />
                    </div>
                </CopyToClipboard>
            </div>

            <SubwayDirections/>
            <BusDirections/>
            <DrivingDirections/>
        </section>
    );
}

