import './Registry.scss';
import React from "react";
import {Box} from "@mui/material";
import logGivingEvent from "../logGivingEvent";
import {useTextConfig, useTextResources} from "../../../Contexts/TextResourcesContext";
import KoreanPaymentOptions from "./KoreanPaymentOptions";

export default function Registry() {
    const resources = useTextResources().giving.registry;
    const config = useTextConfig().registry;

    return (
        <Box component="section" id="registry">
            <h2>{resources.title}</h2>

            {
                resources.introduction ?
                    <p>{resources.introduction}</p> :
                    null
            }

            <KoreanPaymentOptions party="groom"/>
            <KoreanPaymentOptions party="bride"/>

            <div className="overseas">
                <h3>{resources.overseasTitle}</h3>

                <ul className="payment-options">
                    {config.overseas.map((link, index) => (
                        <li key={index}>
                            <a href={link.url}
                                target="_blank"
                                rel="external noreferrer"
                                onClick={() => logGivingEvent("overseas_link_clicked", link.title)}
                            >
                                {link.title}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </Box>
    );
}