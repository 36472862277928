import CommentDto from "../../../CommentDto";
import React, {useEffect, useState} from "react";
import {Box, Collapse} from "@mui/material";
import {CommentPrompt} from "./CommentPrompt";
import CommentForm from "./CommentForm";

export default function CommentFormBox(props: {
    enabled: boolean,
    previouslySubmitted: boolean,
    open: () => void,
    success: (newComment: CommentDto) => void
}) {
    const [formOpen, setFormOpen] = useState(false);
    const [autoFocus, setAutoFocus] = useState(false);

    useEffect(() => {
        setFormOpen(props.enabled && !props.previouslySubmitted);
    }, [props.previouslySubmitted, props.enabled]);

    return (
        <Box className="comment-form-box">
            <Collapse in={!formOpen} timeout="auto" unmountOnExit>
                <CommentPrompt
                    enabled={props.enabled}
                    previouslySubmitted={props.previouslySubmitted}
                    activated={() => {
                        setFormOpen(true);
                        setAutoFocus(true);
                        props.open();
                    }}
                />
            </Collapse>

            <Collapse in={formOpen} timeout="auto" unmountOnExit>
                <CommentForm
                    autoFocus={autoFocus}
                    cancelEnabled={props.previouslySubmitted}
                    submitted={newComment => {
                        props.success(newComment);
                        setFormOpen(false);
                    }}
                    cancelled={() => setFormOpen(false)}
                />
            </Collapse>
        </Box>
    );
}