import React, {ReactNode, useContext} from "react";
import {Alert, AlertColor, Snackbar} from "@mui/material";

export class Notifier {
    private readonly _notify?: (message: string) => void;
    private readonly _error?: (message: string) => void;

    constructor(notify?: (message: string) => void, error?: (message: string) => void) {
        this._notify = notify;
        this._error = error;
    }

    public success(message: string) {
        this._notify?.(message);
    }

    public error(message: string) {
        this._error?.(message);
    }
}

const NotifierContext = React.createContext<Notifier>(new Notifier());

export function NotifierProvider(props: { children: ReactNode }) {
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor>("success");
    const [snackbarText, setSnackbarText] = React.useState("");

    const notifier = new Notifier(
        message => {
            setSnackbarText(message);
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
        },
        message => {
            setSnackbarText(message);
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        });

    return (
        <NotifierContext.Provider value={notifier}>
            {props.children}

            <Snackbar
                open={snackbarOpen}
                onClose={() => setSnackbarOpen(false)}
                autoHideDuration={3000}
                anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                style={{bottom: "70px"}}
            >
                <Alert severity={snackbarSeverity}>{snackbarText}</Alert>
            </Snackbar>
        </NotifierContext.Provider>
    );
}

export function useNotifier() {
    return useContext(NotifierContext);
}