import React, {useEffect, useRef} from "react";
import {ScrollCatcher} from "./ScrollCatcher";
import {appConfig} from "../../appConfig";

export function NaverMap(props: {
    center: Coordinates,
    marker: Coordinates
}) {
    const mapElement = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!appConfig.ncpClientId) {
            return;
        }

        let map: naver.maps.Map | undefined;

        const init = () => {
            if (mapElement.current) {
                map = initializeMap(mapElement.current, props.center, props.marker);
            }
        }

        if (!document.getElementById("naver-map-script")) {
            embedMapScript(init);
        } else {
            init();
        }

        return () => map?.destroy();
    }, [props.center, props.marker]);

    return (
        <div className="naver-map-container">
            <div className="naver-map-target" ref={mapElement}></div>
            <ScrollCatcher/>
        </div>
    );
}

interface Coordinates {
    latitude: number;
    longitude: number;
}

function embedMapScript(callback: () => void) {
    const script = document.createElement("script");
    script.id = "naver-map-script";
    script.async = true;
    script.src = `https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=${appConfig.ncpClientId}`;
    script.onload = callback;
    document.head.appendChild(script);
}
function initializeMap(mapElement: HTMLElement, centerLocation: Coordinates, markerLocation: Coordinates) {
    const {naver} = window;

    if (!naver) {
        return;
    }

    const map = new naver.maps.Map(
        mapElement,
        {
            center: new naver.maps.LatLng(centerLocation.latitude, centerLocation.longitude),
            zoom: 15,
            zoomControl: true,
            zoomControlOptions: {
                position: naver.maps.Position.TOP_LEFT,
                style: naver.maps.ZoomControlStyle.SMALL
            }
        });

    new naver.maps.Marker({
        position: new naver.maps.LatLng(markerLocation.latitude, markerLocation.longitude),
        map
    });

    return map;
}