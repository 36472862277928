import './GallerySection.scss';
import React from "react";
import {GalleryAlbum} from "./GalleryAlbum";
import {InstagramButton} from "./InstagramButton";
import {AppSection} from "../../AppSection";
import {useTextResources} from "../../Contexts/TextResourcesContext";

export default function GallerySection() {
    const resources = useTextResources().gallery;

    return (
        <section className="section" id={AppSection.gallery}>
            <h2>{resources.title}</h2>

            <GalleryAlbum/>
            <InstagramButton/>
        </section>
    )
}