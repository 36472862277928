import NavigationIcon from "@mui/icons-material/Navigation";

import React, {CSSProperties} from "react";
import {Button, ButtonGroup} from "@mui/material";
import {isAndroid, isIOS} from "react-device-detect";
import {useTextResources} from "../../Contexts/TextResourcesContext";
import {directionsConfig} from "../../Resources/directionsConfig";

export function NavigationLauncher() {
    const resources = useTextResources().directions.navigation;

    const buttonStyle: CSSProperties = {
        flexBasis: "50%",
        flexGrow: 1,
        color: "var(--default-text-color)",
        borderColor: "rgba(0, 0, 0, 0.12)",
        display: "inline-flex",
        alignItems: "center",
        gap: "4px",
        textTransform: "none",
        whiteSpace: "nowrap",
        padding: "12px 0"
    };

    return <>
        <ButtonGroup
            size="medium"
            variant="text"
            style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                background: "#fcf7f5",
                border: "1px solid rgba(0, 0, 0, 0.06)",
                marginTop: 0,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0
        }}>
            {
                isAndroid ?
                    <Button
                        href={directionsConfig.androidLink}
                        style={buttonStyle}
                    >
                        <NavigationIcon
                            fontSize="small"
                            sx={{
                                color: "#93786a",
                                marginRight: "4px"
                            }}
                        />
                        <span>{resources.androidLinkName}</span>
                    </Button> :
                    <></>
            }

            {
                isIOS ?
                    <Button
                        href={directionsConfig.iosLink}
                        style={buttonStyle}
                    >
                        <NavigationIcon
                            fontSize="small"
                            sx={{
                                color: "#93786a"
                            }}
                        />
                        <span>{resources.iosLinkName}</span>
                    </Button> :
                    <></>
            }

            <Button
                className="naver-map-button"
                href={directionsConfig.webLink}
                style={buttonStyle}
                target="_blank"
                rel="external noreferrer"
            >
                <span>{resources.webLinkName}</span>
            </Button>
        </ButtonGroup>
    </>;
}