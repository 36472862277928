import React from "react";

export default function OrderOfEvents() {
    return (
        <div className="introduction-text order-of-events">
            <p>
                결혼식은 오후 5시에 시작합니다.<br/>
                식사 후에 리셉션이 있습니다.
            </p>
        </div>
    );
}