import {Fragment} from "react";

export default function LineBreaksToElements(props: { text: string }) {
    return <>
        {
            props.text
                .split('\n\n')
                .map((paragraph, index) =>
                    <p key={index}>
                        {
                            paragraph
                                .split('\n')
                                .map((line, divIndex) =>
                                    <Fragment key={divIndex}>{line}<br/></Fragment>)
                        }
                    </p>)
        }
    </>;
}