import React from "react";
import {useTextConfig, useTextResources} from "../../../Contexts/TextResourcesContext";
import logGivingEvent from "../logGivingEvent";

export default function KakaoPayButton(props: {
    party: "groom" | "bride",
}) {
    const resources = useTextResources().giving.registry;
    const config = useTextConfig().registry[props.party];

    return (
        <a
            href={config.kakaoPayLink}
            className="kakao-pay-button"
            rel="external noreferrer"
            target="_blank"
            onClick={() => logGivingEvent("kakao_pay_clicked", config.kakaoPayLink)}
        >
            {resources.kakaoPayButton}
        </a>
    );
}