import React, {createContext, ReactNode, useContext, useEffect} from "react";
import TextResources from "../Resources/TextResources";
import {textResourcesKo} from "../Resources/textResourcesKo";
import {textResourcesEn} from "../Resources/textResourcesEn";
import {textConfigKo} from "../Resources/textConfigKo";
import {textConfigEn} from "../Resources/textConfigEn";
import TextConfig from "../Resources/TextConfig";

const TextResourcesContext = createContext<TextResources>(textResourcesKo);
const TextConfigContext = createContext<TextConfig>(textConfigKo);

export function TextResourcesProvider(props: {
    children: ReactNode,
    lang: "ko" | "en"
}) {
    const [resources, setResources] = React.useState(getResources(props.lang));
    const [config, setConfig] = React.useState(getConfig(props.lang));
    const { lang } = props;

    useEffect(() => {
        setResources(getResources(lang));
        setConfig(getConfig(lang));
    }, [lang]);

    return (
        <TextResourcesContext.Provider value={resources}>
            <TextConfigContext.Provider value={config}>
                {props.children}
            </TextConfigContext.Provider>
        </TextResourcesContext.Provider>
    );
}

function getResources(lang: "ko" | "en") {
    return lang === "ko" ? textResourcesKo : textResourcesEn;
}

function getConfig(lang: "ko" | "en") {
    return lang === "ko" ? textConfigKo : textConfigEn;
}

export function useTextResources() {
    return useContext(TextResourcesContext);
}

export function useTextConfig() {
    return useContext(TextConfigContext);
}