import CommentDto from "../../../CommentDto";
import React, {useEffect, useState} from "react";
import {Button, CircularProgress, TextField} from "@mui/material";
import logGivingEvent from "../logGivingEvent";
import {useTextResources} from "../../../Contexts/TextResourcesContext";
import {useNotifier} from "../../../Contexts/NotifierContext";
import {useCommentingApiClient} from "../../../Contexts/CommentingApiClientContext";

export default function CommentForm(props: {
    submitted: (newComment: CommentDto) => void,
    autoFocus: boolean,
    cancelEnabled: boolean,
    cancelled: () => void
}) {
    const [authorName, setAuthorName] = useState("");
    const [commentText, setCommentText] = useState("");
    const [authorNameError, setAuthorNameError] = useState("");
    const [commentTextError, setCommentTextError] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);

    const authorNameRef = React.useRef<HTMLInputElement>(null);
    const commentTextRef = React.useRef<HTMLInputElement>(null);

    const resources = useTextResources().giving.comments;
    const apiClient = useCommentingApiClient();
    const notifier = useNotifier();

    useEffect(() => {
        if (props.autoFocus) {
            authorNameRef.current?.focus();
        }
    }, [props.autoFocus]);

    const resetValidationErrors = () => {
        setAuthorNameError("");
        setCommentTextError("");
    }

    const validateForm = () => {
        let valid = true;

        if (authorName === "") {
            setAuthorNameError(resources.form.errors.authorRequired);
            authorNameRef.current?.focus();
            valid = false;
        }

        if (commentText === "") {
            setCommentTextError(resources.form.errors.textRequired);
            valid && commentTextRef.current?.focus();
            valid = false;
        }

        return valid;
    }

    const submitForm = async () => {
        if (!apiClient) {
            return;
        }

        logGivingEvent("comment_submit_clicked");

        resetValidationErrors();

        if (!validateForm()) {
            return;
        }

        setFormSubmitted(true);

        let response: Response;

        try {
            response = await apiClient.postComment(authorName, commentText);
        } catch (error: any) {
            notifier.error(resources.form.errors.general);
            setFormSubmitted(false);
            logGivingEvent("comment_submit_server_error", error.message);

            return;
        }

        if (response.status === 400) {
            const errorDto = await response.json();
            let compositeError = "";

            Object.keys(errorDto.errors).forEach((key: string) => {
                const errorString = errorDto.errors[key].join("\n");

                if (compositeError !== "") {
                    compositeError += "\n";
                }

                compositeError += errorString;

                switch (key) {
                    case "Author":
                        setAuthorNameError(errorString);
                        break;

                    case "Text":
                        setCommentTextError(errorString);
                        break;
                }
            });

            notifier.error(compositeError);
            setFormSubmitted(false);
            logGivingEvent("comment_submit_validation_error", compositeError)

            return;
        }

        const data = await response.json() as CommentDto;

        setFormSubmitted(false);
        resetValidationErrors();
        props.submitted(data);

        logGivingEvent("comment_submit_success", data.id);
    };

    const cancelForm = () => {
        resetValidationErrors();
        props.cancelled();
        logGivingEvent("comment_cancel_clicked");
    };

    return (
        <div className="comment-form">
            <TextField
                autoComplete="name"
                value={authorName}
                placeholder={resources.form.authorLabel}
                fullWidth={true}
                size="small"
                required={true}
                inputRef={authorNameRef}
                error={authorNameError !== ""}
                helperText={authorNameError}
                disabled={formSubmitted}
                inputProps={{maxLength: 40}}
                style={{backgroundColor: "white"}}
                onChange={(event) => {
                    setAuthorName(event.target.value);
                    setAuthorNameError("");
                }}
                onFocus={() => {
                    logGivingEvent("comment_author_focused");
                }}
                onBlur={() => {
                    logGivingEvent("comment_author_exited", authorName.substring(0, 100), authorName.length);
                }}
            />

            <TextField value={commentText}
                placeholder={resources.form.textLabel}
                multiline={true}
                rows={4}
                fullWidth={true}
                size="small"
                required={true}
                inputRef={commentTextRef}
                error={commentTextError !== ""}
                helperText={commentTextError}
                disabled={formSubmitted}
                style={{backgroundColor: "white"}}
                onChange={(event) => {
                    setCommentText(event.target.value);
                    setCommentTextError("");
                }}
                onFocus={() => {
                    logGivingEvent("comment_text_focused");
                }}
                onBlur={() => {
                    logGivingEvent("comment_text_exited", commentText.substring(0, 100), commentText.length);
                }}
            />

            <div className="button-bar">
                <Button
                    type="submit"
                    variant="contained"
                    disabled={!apiClient || formSubmitted || authorName === "" || commentText === ""}
                    onClick={submitForm}
                >
                    {resources.form.submitButton}
                </Button>

                <Button
                    type="button"
                    variant="text"
                    disabled={formSubmitted}
                    style={props.cancelEnabled ? {} : {display: "none"}}
                    onClick={cancelForm}
                >
                    {resources.form.cancelButton}
                </Button>
            </div>

            {
                formSubmitted ?
                    <div
                        style={{
                            marginTop: "24px",
                            textAlign: "center",
                        }}
                    >
                        <CircularProgress/>
                    </div> :
                    null
            }
        </div>
    );
}