if (process.env.REACT_APP_GA_TRACKING_ID === undefined) {
    console.error("REACT_APP_GA_TRACKING_ID is not defined");
}

if (process.env.REACT_APP_COMMENTING_API_URI === undefined) {
    console.error("REACT_APP_COMMENTING_API_URI is not defined");
}

if (process.env.REACT_APP_NCP_CLIENT_ID === undefined) {
    console.error("REACT_APP_NCP_CLIENT_ID is not defined");
}

export const appConfig = {
    gaTrackingId: process.env.REACT_APP_GA_TRACKING_ID,
    commentingApiUri: process.env.REACT_APP_COMMENTING_API_URI,
    ncpClientId: process.env.REACT_APP_NCP_CLIENT_ID,
};