import GalleryConfig from "../Components/Gallery/GalleryConfig";

export const galleryConfig: GalleryConfig = {
    path: "/assets/gallery",
    thumbnails: [
        {
            zoom: 1,
            path: '/assets/gallery/thumbnails/1x'
        },
        {
            zoom: 2,
            path: '/assets/gallery/thumbnails/2x'
        },
        {
            zoom: 3,
            path: '/assets/gallery/thumbnails/3x'
        }
    ],
    dimensions: {
        width: 2100,
        height: 1400
    },
    photos: [
        {
            filename: "SE1_5147.jpg",
            landscape: false
        },
        {
            filename: "LJ1_8085.jpg",
            landscape: false
        },
        {
            filename: "SE1_5123.jpg",
            landscape: false
        },
        {
            filename: "2JW03406.jpg",
            landscape: true
        },
        {
            filename: "2JW04383.jpg",
            landscape: false
        },
        {
            filename: "2JW04700.jpg",
            landscape: true
        },
        {
            filename: "김소현매튜님-194.jpg",
            landscape: false
        },
        {
            filename: "김소현매튜님-206.jpg",
            landscape: false
        },
        {
            filename: "김소현매튜님3-3283.jpg",
            landscape: false
        },
        {
            filename: "LJ2_5727.jpg",
            landscape: false
        },
        {
            filename: "LJ2_5881.jpg",
            landscape: false
        },
        {
            filename: "LJ2_6123.jpg",
            landscape: false
        },
        {
            filename: "2JW04124.jpg",
            landscape: true
        },
        {
            filename: "2JW03868.jpg",
            landscape: false
        },
        {
            filename: "2JW04353.jpg",
            landscape: true
        },
        {
            filename: "김소현매튜님3-3335.jpg",
            landscape: false
        },
        {
            filename: "김소현매튜님-028.jpg",
            landscape: false
        },
        {
            filename: "김소현매튜님-664.jpg",
            landscape: false
        },
        {
            filename: "2JW04519.jpg",
            landscape: false
        },
        {
            filename: "2JW04598_1.jpg",
            landscape: false
        },
        {
            filename: "2JW04870.jpg",
            landscape: false
        },
        {
            filename: "DSCF0808.jpg",
            landscape: false
        },
        {
            filename: "DSCF0938a12.jpg",
            landscape: false
        },
        {
            filename: "DSCF1252.jpg",
            landscape: false
        },
        {
            filename: "LJ1_7982.jpg",
            landscape: false
        },
        {
            filename: "LJ1_8057.jpg",
            landscape: false
        },
        {
            filename: "LJ1_8235.jpg",
            landscape: false
        },
        {
            filename: "LJ2_5565.jpg",
            landscape: false
        },
        {
            filename: "LJ2_5769.jpg",
            landscape: false
        },
        {
            filename: "LJ2_6238.jpg",
            landscape: false
        },
        {
            filename: "SE1_5176.jpg",
            landscape: false
        },
        {
            filename: "김소현매튜님-086.jpg",
            landscape: false
        },
        {
            filename: "김소현매튜님-691.jpg",
            landscape: false
        },
        {
            filename: "김소현매튜님3-3260.jpg",
            landscape: false
        },
        {
            filename: "김소현매튜님3-3390.jpg",
            landscape: false
        },
        {
            filename: "4.jpg",
            landscape: false
        },
        {
            filename: "김소현매튜님-빈티지스냅3-3671.jpg",
            landscape: false
        },
        {
            filename: "김소현매튜님-빈티지스냅3-3993.jpg",
            landscape: false
        },
        {
            filename: "3.jpg",
            landscape: false
        },
    ],
    instagramLink: "https://www.instagram.com/sohyunandmatthew"
};