import React, {useEffect, useRef} from "react";

export function ScrollCatcher() {
    const element = useRef(null);
    const [catchScroll, setCatchScroll] = React.useState(false);

    useEffect(() => {
        const throttler = (before: () => void, after: () => void, delay: number) => {
            let wait = 0;

            return () => {
                if (wait === 0) {
                    wait++;
                    before();
                    setTimeout(() => {
                        after();
                        wait--;
                    }, delay);
                }
            };
        }

        let scrollHandler = throttler(
            () => setCatchScroll(true),
            () => setCatchScroll(false),
            1500);
        window.addEventListener("scroll", scrollHandler, {passive: true});

        return () => window.removeEventListener("scroll", scrollHandler)
    });

    return (
        <div ref={element}
            className="scroll-catcher"
            style={{inset: "0 0 0 0", position: "absolute", pointerEvents: catchScroll ? "all" : "none"}}></div>
    );

}