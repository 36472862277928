import CommentDto from "../CommentDto";

export const comments: CommentDto[] = [
    {
        "id": "fb1f869e-3b8d-4f77-85bd-0b10c838c946",
        "author": "김경아",
        "text": "멋진 신랑,아름다운 신부!\n눈이 부시도록 푸르른 날들이 둘의 앞날에 펼쳐지기를~~ 결혼 진심으로 축하해❤",
        "mine": false,
        "timestamp": 1690244469
    },
    {
        "id": "a784a585-2f0d-4fe0-9f12-192bc8b9ddca",
        "author": "김혜란",
        "text": "소현아...홍지엄마...소현이가 결혼을하는구나..초등학교 1학년때 처음봤을때  단발머리 소현이 넘 이뻤는데 웨딩사진도 이쁘게 잘나왔네...축하하고 행복하렴...^^",
        "mine": false,
        "timestamp": 1693558185
    },
    {
        "id": "bf991343-c5d5-4f7d-8d12-27c142e4a729",
        "author": "손예진",
        "text": "언니 기쁜 소식 축하드려요❤️",
        "mine": false,
        "timestamp": 1693634867
    },
    {
        "id": "840d5aa9-04c1-4f4a-9361-30aaf2c26708",
        "author": "아바",
        "text": "너무 미인이세여 >_< 소현아 결혼 축하해♥︎♥︎",
        "mine": false,
        "timestamp": 1690692517
    },
    {
        "id": "aa7a0924-b640-4670-a3af-3e753cbafb68",
        "author": "김기정",
        "text": "결혼축하해. 항상 행복하고 멋진 시간이되시길!!! 잘살으라 😆 ㅎㅎ\n매튜형님 미남이십니다!! 나중에 함 인사드리겠습니다^^",
        "mine": false,
        "timestamp": 1690692489
    },
    {
        "id": "75618937-9c23-4d6e-9e78-67eb20267144",
        "author": "매튜",
        "text": "신부님이 엄청 예쁘네 😍",
        "mine": false,
        "timestamp": 1690203901
    },
    {
        "id": "c8804dcb-9f6d-43c8-9f3f-7659be274444",
        "author": "이주현",
        "text": "세상에서 제일 예쁘고 멋있습니다🩷",
        "mine": false,
        "timestamp": 1691757562
    },
    {
        "id": "eeab7efb-6a18-4c95-96df-9187fb2011f8",
        "author": "결혼 축하하며 행복하기를 기도할께~♡",
        "text": "엘레사벳 !\n할머니 거동이 안되셔서 축하해주러 못가 미안해. 딸 소현이와 사위 행복한 생활이루기를 기도할께. 고모와 소현이 부부도 보고싶다. 언젠가는 만날수 있기를~♡",
        "mine": false,
        "timestamp": 1693606271
    },
    {
        "id": "1c3cbc37-499e-473f-8479-92a1de9f1e4e",
        "author": "김해든(김미자)루치아",
        "text": "하느님의 은총아래서 알콩달콩 행복한 생활이 되도록 기도해줄께~♡\n둘이 잘 어울리네.",
        "mine": false,
        "timestamp": 1691100353
    },
    {
        "id": "56e57245-dc94-40c6-8530-99e2311a76a8",
        "author": "Yoon",
        "text": "Congratulation!",
        "mine": false,
        "timestamp": 1693620730
    },
    {
        "id": "f8a8a13c-7262-4111-95cf-a77cbf1f9c1f",
        "author": "슬기",
        "text": "결혼 축하해 항상 행복하길 바라❤️",
        "mine": false,
        "timestamp": 1691757651
    },
    {
        "id": "07f88aa5-5f0c-4f30-b633-bd84d054d1c2",
        "author": "Sang",
        "text": "Matt! Congratulations my friend! May you have a blessed marriage. I am so happy for you",
        "mine": false,
        "timestamp": 1692834479
    },
    {
        "id": "b4aaae21-b1ae-412e-b03f-be10a6f2a279",
        "author": "방미선",
        "text": "소현아 결혼 너무 축하해!!\n행복하게 잘살아♡",
        "mine": false,
        "timestamp": 1693638616
    },
    {
        "id": "f0b74d64-db31-4458-99e1-c53c51f1691c",
        "author": "Hannah",
        "text": "Congrats to a beautiful union of two very special people. May your marriage be filled with joy, love, peace, patience, forgiveness, understanding, comunication, and God's presence♡.",
        "mine": false,
        "timestamp": 1693643888
    },
    {
        "id": "accb3e79-9bb9-4071-85f3-ca2d8bcd71ed",
        "author": "아리안",
        "text": "신랑 신부님이 천상배필 ,쏘울메이트 ,,둘이 닮았네,,,",
        "mine": false,
        "timestamp": 1690205599
    },
    {
        "id": "2fdeea21-9e22-4579-932e-d92c3e056cca",
        "author": "안드레아 유",
        "text": "소중한 인연, 앞으로 축복과 사랑 가득한 날들만 보내길 바랍니다.",
        "mine": false,
        "timestamp": 1691757680
    },
    {
        "id": "57b16140-8979-40e1-a6e7-f04667f6f4e7",
        "author": "소현",
        "text": "신랑님이 엄청 멋있네 🥰",
        "mine": false,
        "timestamp": 1690204553
    },
    {
        "id": "64d77ab3-46bd-4b64-b155-fec6e5f17c28",
        "author": "James",
        "text": "Matt, many happy returns. All the best for your wedding and I hope you enjoy a long and prosperous life together.",
        "mine": false,
        "timestamp": 1693639708
    }
]