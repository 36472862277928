import CommentDto from "../../../CommentDto";
import React, {useEffect} from "react";
import {Button, CircularProgress} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import logGivingEvent from "../logGivingEvent";
import CommentListItem from "./CommentListItem";
import {useTextResources} from "../../../Contexts/TextResourcesContext";
import {useCommentsState} from "../../../Contexts/CommentsStateContext";

export default function CommentList(props: {
    locale: string
}) {
    const [visibleCount, setVisibleCount] = React.useState(6);
    const commentsState = useCommentsState();
    const listRef = React.useRef<HTMLUListElement>(null);

    const incrementVisibleCount = () => setVisibleCount(visibleCount + 12);

    const resources = useTextResources().giving.comments;

    useEffect(() => {
        let timeout: number | undefined = undefined

        const listener = () => {
            if (timeout !== undefined || !commentsState?.comments) {
                return;
            }

            timeout = window.setTimeout(() => {
                if (listRef.current &&
                    listRef.current.getBoundingClientRect().top < window.innerHeight * 0.5) {
                    commentsState.updateSeen();
                    logGivingEvent("comments_viewed", commentsState.comments[0]?.id,
                        commentsState.comments[0]?.timestamp);
                }

                timeout = undefined;
            }, 250);
        };

        window.addEventListener('scroll', listener, {passive: true});

        return () => {
            window.removeEventListener('scroll', listener);

            if (timeout !== undefined) {
                window.clearTimeout(timeout);
            }
        }
    }, [commentsState]);

    return (
        commentsState?.comments ?
            <>
                <ul ref={listRef}>
                    {commentsState.comments
                        .slice(0, visibleCount)
                        .map((comment: CommentDto, index: number) =>
                        (
                            <CommentListItem
                                comment={comment}
                                key={index}
                                locale={props.locale}
                                newItem={commentsState.isNew(comment)}
                                resources={resources}
                            />
                        ))
                    }
                </ul>

                <div style={{
                    marginTop: "24px",
                    textAlign: "center",
                    display: visibleCount >= commentsState.comments.length ? "none" : "block",
                }}>
                    <Button
                        className="see-more-button"
                        size="small"
                        onClick={() => {
                            incrementVisibleCount();
                            logGivingEvent("comments_see_more_clicked", undefined, visibleCount);
                        }}
                        style={visibleCount > commentsState.comments.length ?
                            {display: "none"} :
                            {
                                display: "inline-flex",
                                alignItems: "center",
                                gap: "4px"
                            }
                        }
                    >
                        <span className="see-more-text">
                            {
                                resources.seeMoreButton.replace("{0}",
                                    (commentsState.comments.length - visibleCount)
                                        .toLocaleString(props.locale))
                            }
                        </span>
                        <ExpandMore/>
                    </Button>
                </div>
            </> :
            <div
                style={{
                    marginTop: "48px",
                    textAlign: "center",
                }}
            >
                <CircularProgress/>
            </div>
    );
}

