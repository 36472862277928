import React, {useState} from "react";
import {GalleryLightbox, useOpenGalleryLightbox} from "./GalleryLightbox";
import {Button, ImageList, ImageListItem} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import "./GalleryAlbum.scss";
import logGalleryEvent from "./logGalleryEvent";
import {useTextResources} from "../../Contexts/TextResourcesContext";
import {galleryConfig} from "../../Resources/galleryConfig";

export function GalleryAlbum() {
    const [visibleCount, setVisibleCount] = useState(12);
    const [lightboxIndex, setLightboxIndex] = useState(-1);

    const openLightbox = useOpenGalleryLightbox();
    const resources = useTextResources().gallery;

    return (
        <div className="gallery-album">
            <ImageList cols={3} gap={5}>
                {galleryConfig.photos.map((photo, index) => (
                    <ImageListItem key={index}
                        sx={{display: index < visibleCount ? 'inline-block' : 'none'}}
                        onClick={() => {
                            setLightboxIndex(index);
                            openLightbox();
                            logGalleryEvent("photo_clicked", photo.filename, index);
                        }}>
                            <img key={index}
                                src={photo.filename}
                                srcSet={galleryConfig.thumbnails.map((profile) => {
                                    return `${profile.path}/${photo.filename} ${profile.zoom}x`;
                                }).join(', ')}
                                alt=""
                                loading="lazy"
                                style={{
                                    aspectRatio: 1,
                                    objectFit: 'cover',
                                    cursor: 'pointer'
                                }}/>
                    </ImageListItem>
                ))}
            </ImageList>

            <Button
                className="see-more-button"
                onClick={() => {
                    setVisibleCount(visibleCount + 12);
                    logGalleryEvent("photos_see_more_clicked", undefined, visibleCount);
                }}
                style={visibleCount > galleryConfig.photos.length ?
                    {display: "none"} :
                    {}
                }
            >
                <span className="see-more-text">{resources.seeMoreButton}</span><ExpandMore/>
            </Button>

            <GalleryLightbox index={lightboxIndex}/>
        </div>
    )
}