import CommentDto from "../CommentDto";
import React, {createContext, ReactNode, useContext, useEffect, useState} from "react";
import {useCommentingApiClient} from "./CommentingApiClientContext";

const previousTimestamp = localStorage.getItem("lastCommentTimestamp") ?? 0;

export default function CommentsStateProvider(props: {
    children: ReactNode
}) {
    const [comments, setComments] = useState<CommentDto[] | null>(null);
    const [sessionTimestamp, setSessionTimestamp] = React.useState(previousTimestamp);

    const apiClient = useCommentingApiClient();

    useEffect(() => {
        if (!apiClient) {
            return;
        }

        const fetchInterval = 10000;
        const newFetchCount = 18;
        const newSkipCount = 18;

        let interval : number | undefined;
        let fetchCount  = 0;
        let skipCount = 0;

        const fetchComments = async () => {
            if (fetchCount <= 0 && skipCount > 0) {
                skipCount--;
                return;
            }

            if (fetchCount > 0) {
                fetchCount--;
            }

            skipCount = newSkipCount;

            try {
                let data = await apiClient.getComments();
                setComments(data);
            } catch (e) {
                console.error(e);
            }
        }

        const resetInterval = () => {
            fetchCount = newFetchCount;

            if (!interval) {
                fetchComments().then(() => {});
                interval = window.setInterval(fetchComments, fetchInterval);
            }
        }

        resetInterval();

        window.addEventListener('scroll', resetInterval);
        window.addEventListener('focus', resetInterval);

        return () => {
            window.removeEventListener('scroll', resetInterval);
            window.removeEventListener('focus', resetInterval);

            if (interval) {
                window.clearInterval(interval);
                interval = undefined;
            }
        }
    }, [apiClient]);

    return (
        <CommentsStateContext.Provider
            value={{
                comments: comments ?? [],
                isNew: (comment) => comment.timestamp > previousTimestamp,
                countUnseen: () =>
                    comments?.filter(comment => !comment.mine && comment.timestamp > sessionTimestamp).length ??
                    0,
                updateSeen: () => {
                    if (!comments) {
                        return;
                    }

                    const timestamp = comments.length > 0 ? comments[0].timestamp : 0;
                    localStorage.setItem("lastCommentTimestamp", timestamp.toString());
                    setSessionTimestamp(timestamp);
                },
                isReadOnly: () => apiClient?.isReadOnly() ?? true,
                previouslySubmitted: () => comments?.some(comment => comment.mine) ?? false,
                addComment: (newComment: CommentDto) => {
                    if (comments && !comments.some(comment => comment.id === newComment.id)) {
                        setComments([newComment].concat((comments)));
                    }
                },
                deleteComment: async (id: string) => {
                    const response = await apiClient!.deleteComment(id);

                    if (comments) {
                        setComments(comments.filter(comment => comment.id !== id));
                    }

                    return response;
                }
            }}
        >
            {props.children}
        </CommentsStateContext.Provider>
    );
}

interface CommentsState {
    readonly comments: CommentDto[];
    isNew(comment: CommentDto): boolean;
    countUnseen(): number;
    updateSeen(): void;
    isReadOnly(): boolean;
    previouslySubmitted(): boolean;
    addComment(comment: CommentDto): void;
    deleteComment(id: string): Promise<Response>;
}

const CommentsStateContext = createContext<CommentsState | null>(null);

export function useCommentsState() {
    return useContext(CommentsStateContext);
}