import TextConfig from "./TextConfig";

export const textConfigKo: TextConfig = {
    cover: {
        groomFullName: "매튜 수하키",
        brideFullName: "김소현",
        weddingDate: "2023.9.2 토요일",
        weddingTime: "오후 5시",
        weddingLocation: "반포한강공원 마리나파크"
    },

    invitation: {
        message:
            "창공의 별빛처럼 빛나는 사랑,\n" +
            "하나님의 축복 아래 우리 두 사람은 만납니다.\n" +
            "\n" +
            "두 마음이 하나로 묶이는 시간,\n" +
            "올바른 때에 이끌려 어우러진 인연이 됩니다.\n" +
            "\n" +
            "기쁨과 행복이 넘치는 그 날,\n" +
            "함께 빛나는 우리의 시작을 축하해 주세요.",
        groom: {
            fatherName: "피터",
            motherName: "수잔 수하키",
            relationship: "의 장남",
            name: "매튜"
        },
        bride: {
            fatherName: "김재용",
            motherName: "김지원",
            relationship: "의 장녀",
            name: "소현"
        },
        eventDate: "2023-09-02"
    },

    directions: {
        locationName: "서래나루 마리나파크 2층",
        fullAddress: "서울시 서초구 올림픽대로 2085-18",
        navigationAddress: "서울시 서초구 올림픽대로 2085-18",
        drivingDirections: {
            notice: "주차장 만차일 경우가 있으니 대중교통 이용을 권장 드립니다.",
            directions: [
                {
                    title: "내비게이션",
                    note: "‟반포한강공원 2주차장”을 입력하세요.",
                    turns: []
                }
            ],
            extendedDirections: [
                {
                    title: "올림픽대로 (공항방면)",
                    turns: [
                        "올림픽대로 공항방면 반포대교 통과",
                        "서래나루 주차장(반포 한강공원)"
                    ]
                },
                {
                    title: "올림픽대로 (잠실방면)",
                    turns: [
                        "동작대교 분기점 이수고가차도",
                        "성모병원 사거리 좌회전",
                        "잠수교 입구 원형교차로 9시 방향"
                    ]
                },
                {
                    title: "성수→강남 방면",
                    turns: [
                        "잠수교 이용",
                        "잠수교 남단 원형교차로 세빛섬 방향 진입"
                    ]
                }
            ]
        },
        subwayDirections: {
            stops: [
                {
                    name: "고속터미널역",
                    lines: [
                        {
                            name: "3호선",
                            className: "subway-line-3"
                        },
                        {
                            name: "7호선",
                            className: "subway-line-7"
                        },
                        {
                            name: "9호선",
                            className: "subway-line-9"
                        }
                    ],
                    details: "6번 출구 앞 무료셔틀버스 운행 (10~15분 간격)"
                },
                {
                    name: "신반포역",
                    lines: [
                        {
                            name: "9호선",
                            className: "subway-line-9"
                        }
                    ],
                    distance: "10분 도보"
                }
            ]
        },
        busDirections: {
            stops: [
                {
                    name: "반포한강공원 세빛섬 정류장",
                    lines: [
                        {
                            name: "405",
                            className: "blue-bus"
                        },
                        {
                            name: "740",
                            className: "blue-bus"
                        }
                    ],
                    distance: "5분 도보"
                },
                {
                    name: "반포대교남단 한강시민공원입구 정류장",
                    lines: [
                        {
                            name: "143",
                            className: "blue-bus"
                        },
                        {
                            name: "401",
                            className: "blue-bus"
                        },
                        {
                            name: "406",
                            className: "blue-bus"
                        }
                    ],
                    distance: "20분 도보"
                },
            ]
        }
    },

    registry: {
        groom: {
            bankTransfer: {
                bankName: "국민은행",
                accountNumber: "080802-04-096261",
                accountName: "SUHOCKIMAT"
            },
            kakaoPayLink: "https://kko.to/4e32kqR-lB"
        },
        bride: {
            bankTransfer: {
                bankName: "하나은행",
                accountNumber: "869-910251-66307",
                accountName: "김소현"
            },
            kakaoPayLink: "https://kko.to/3nndnjtm3Q"
        },
        overseas: [
            {
                title: "아마존 레지스티리",
                url: "https://www.amazon.com/wedding/share/suhocki-kim"
            },
            {
                title: "벤모 송금",
                url: "https://venmo.com/suhock"
            }
        ]
    }
};