import CommentingApiClient from "./CommentingApiClient";

export default class HttpCommentingApiClient implements CommentingApiClient {
    private readonly baseUrl: string;
    private readonly authorToken: string;

    public constructor(
        baseUrl: string,
        authorToken: string
    ) {
        if (baseUrl === undefined || baseUrl === null || baseUrl === "") {
            throw new Error("baseUrl is required");
        }

        if (authorToken === undefined || authorToken === null || authorToken === "") {
            throw new Error("authorToken is required");
        }

        this.baseUrl = baseUrl;
        this.authorToken = authorToken;
    }

    public async getComments() {
        return (await fetch(`${this.baseUrl}/comments?authorToken=${this.authorToken}`)).json();
    }

    public postComment(
        authorName: string,
        commentText: string
    ) {
        return fetch(`${this.baseUrl}/comments`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                authorToken: this.authorToken,
                author: authorName,
                text: commentText
            })
        });
    }

    public deleteComment(
        id: string
    ) {
        return fetch(`${this.baseUrl}/comments`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                id: id,
                authorToken: this.authorToken
            })
        });
    }

    public isReadOnly(): boolean {
        return false;
    }
}