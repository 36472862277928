import {Button} from "@mui/material";
import logGivingEvent from "../logGivingEvent";
import React from "react";
import {useTextResources} from "../../../Contexts/TextResourcesContext";

export function CommentPrompt(props: {
    enabled: boolean,
    previouslySubmitted: boolean,
    activated: () => void
}) {
    const resources = useTextResources().giving.comments;

    return (
        <div className="comment-prompt">
            {
                props.enabled ?
                    (props.previouslySubmitted ?
                        <div className="thank-you">{resources.submittedIntro}</div> :
                        <></>) :
                    <div className="commenting-disabled">{resources.commentingDisabled}</div>
            }

            {
                props.enabled ?
                    <Button
                        variant="contained"
                        style={{textTransform: "none"}}
                        onClick={() => {
                            props.activated();
                            logGivingEvent("show_comment_form_clicked");
                        }}
                    ><span>{resources.openButton}</span></Button> :
                    <></>
            }
        </div>
    );
}