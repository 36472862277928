import "./InvitationSection.scss";
import React from "react";
import {AppSection} from "../../AppSection";
import {useTextConfig, useTextResources} from "../../Contexts/TextResourcesContext";
import Calendar from "./Calendar";
import OrderOfEvents from "./OrderOfEvents";
import {Inviters} from "./Inviters";
import LineBreaksToElements from "../LineBreaksToElements";

export default function InvitationSection() {
    const resources = useTextResources().invitation;
    const config = useTextConfig().invitation;

    return (
        <section className="section" id={AppSection.invitation}>
            <div className="banner-image">
                <img
                    src="/assets/invitation-1x.png"
                    srcSet={
                        "/assets/invitation-1x.png 1x, " +
                        "/assets/invitation-2x.png 2x"
                    }
                    alt=""
                />
            </div>

            <h2>{resources.title}</h2>

            <div className="introduction-text">
                <LineBreaksToElements text={config.message}/>
            </div>

            <Inviters/>
            <OrderOfEvents/>
            <Calendar eventDate={new Date(parseDate(config.eventDate))}/>
        </section>
    );
}

function parseDate(date: string) {
    const parts = date.split("-");
    return new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]));
}