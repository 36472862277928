import React from "react";
import Registry from "./Registry/Registry";
import CommentsSection from "./Comments/CommentsSection";
import Sharing from "./Sharing/Sharing";
import "./GivingSection.scss";
import {Box} from "@mui/material";
import {AppSection} from "../../AppSection";
import {useTextResources} from "../../Contexts/TextResourcesContext";

export default function GivingSection() {
    const resources = useTextResources();

    return (
        <Box component="div" className="section" id={AppSection.giving}>
            <Registry/>

            <CommentsSection locale={resources.locale}/>

            <Sharing/>
        </Box>
    );
}