import {useTextConfig, useTextResources} from "../../Contexts/TextResourcesContext";

export function BusDirections() {
    const resources = useTextResources().directions;
    const config = useTextConfig().directions.busDirections;

    return <>
        <h3>{resources.busDirectionsTitle}</h3>

        <ul className="directions-section">
            {
                config.stops.map((stop, index) =>
                    <li key={index}>
                        <div>
                            {stop.name}
                            {
                                stop.distance !== undefined ?
                                    <span className="distance">{stop.distance}</span> :
                                    <></>
                            }
                        </div>

                        <ul className="bus-routes">
                            {
                                stop.lines.map((line, index) =>
                                    <li className={line.className} key={index}>{line.name}</li>)
                            }
                        </ul>

                        {
                            stop.details !== undefined ?
                                <div>{stop.details}</div> :
                                <></>
                        }
                    </li>
                )
            }
        </ul>
    </>;
}