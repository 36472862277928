import {Instagram} from "@mui/icons-material";
import React from "react";
import "./InstagramButton.scss";
import {Button} from "@mui/material";
import logGalleryEvent from "./logGalleryEvent";
import {useTextResources} from "../../Contexts/TextResourcesContext";
import {galleryConfig} from "../../Resources/galleryConfig";

export function InstagramButton() {
    const resources = useTextResources().gallery;

    return (
        galleryConfig.instagramLink ?
            <div className="instagram-button">
                <Button
                    variant="contained"
                    href={galleryConfig.instagramLink}
                    target="_blank"
                    rel="noreferrer noopener"
                    color="primary"
                    onClick={() => {
                        logGalleryEvent("instagram_clicked", galleryConfig.instagramLink);
                    }}
                >
                    <Instagram color="secondary"/><span>{resources.instagramButton}</span>
                </Button>
            </div> :
            <></>
    );
}