import React, {useEffect} from "react";
import {Fab} from "@mui/material";
import "./LanguageToggle.scss";

export function LanguageToggle(props: {
    lang: string,
    change: (lang: "ko" | "en") => void
}) {
    const [lang, setLang] = React.useState(props.lang);
    const [scrollY, setScrollY] = React.useState(window.scrollY);

    useEffect(() => {
        let timeout: number | undefined = undefined

        const listener = () => {
            if (timeout !== undefined) {
                return;
            }

            timeout = window.setTimeout(() => {
                setScrollY(window.scrollY);
                timeout = undefined;
            }, 50);
        };

        window.addEventListener('scroll', listener, {passive: true});

        return () => {
            window.removeEventListener('scroll', listener);

            if (timeout !== undefined) {
                window.clearTimeout(timeout);
            }
        }
    }, []);

    return (
        <Fab
            value={lang}
            variant="circular"
            color="secondary"
            size="medium"
                onClick={() => {
                const newLang = lang === "en" ? "ko" : "en";
                setLang(newLang);
                props.change(newLang);
            }}
            style={{
                opacity: Math.max(
                    0.26,
                    0.26 + ((window.innerHeight - scrollY) / window.innerHeight) * 0.28)
            }}
            className="language-toggle"
        />
    );
}