import CommentDto from "../../../CommentDto";
import {CommentsResources} from "./CommentsResources";
import React from "react";
import {DeleteCommentButton} from "./DeleteCommentButton";
import {CircularProgress} from "@mui/material";
import LineBreaksToElements from "../../LineBreaksToElements";

export default function CommentListItem(props: {
    comment: CommentDto,
    locale: string,
    newItem: boolean,
    resources: CommentsResources
}) {
    const [deleting, setDeleting] = React.useState(false);

    const commentDate = new Date(props.comment.timestamp * 1000);

    return (
        <li className={`comment${props.comment.mine ? " my-comment" : ""}${props.newItem ? " new-comment" : ""}`}
            style={{position: "relative"}}>
            <span className="author">{props.comment.author}</span>

            <span className="date">{
                commentDate.toLocaleString(
                    props.locale,
                    {
                        month: "short",
                        day: "numeric",
                        hour: "numeric",
                        minute: "2-digit",
                        hour12: props.locale === "en-US"
                    })
                    .replace(" 24:", " 00:")
            }</span>

            <div className="text">
                <LineBreaksToElements text={props.comment.text}/>
            </div>

            {
                props.comment.mine ?
                    <DeleteCommentButton
                        comment={props.comment}
                        processing={() => setDeleting(true)}
                        success={() => setDeleting(false)}
                        error={() => setDeleting(false)}/> :
                    null
            }

            <div style={{
                display: deleting ? "flex" : "none",
                position: "absolute",
                inset: "0 0 0 0",
                background: "rgba(255, 255, 255, 0.3)",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <CircularProgress color="inherit" style={{position: "absolute", color: "#ccc"}}/>
            </div>
        </li>
    );
}