import React from "react";
import {useTextConfig} from "../../Contexts/TextResourcesContext";

export function Inviters() {
    return (
        <ul className="inviters">
            <Inviter partyName="groom"/> <Inviter partyName="bride"/>
        </ul>
    );
}

function Inviter(props: {
    partyName: "bride" | "groom"
}) {
    const party = useTextConfig().invitation[props.partyName];

    return (
        <li className="inviter">
            {
                party.fatherName !== undefined ?
                    <span className="father-name">{party.fatherName}</span> :
                    <></>
            }
            {
                party.motherName !== undefined ?
                    <span className="mother-name">{party.motherName}</span> :
                    <></>
            }
            <span className="relationship">{party.relationship}</span>
            <span className="name">{party.name}</span>
        </li>
    );
}