import React from "react";
import logGivingEvent from "../logGivingEvent";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {useNotifier} from "../../../Contexts/NotifierContext";
import {useTextConfig, useTextResources} from "../../../Contexts/TextResourcesContext";

export default function ShowBankInformationButton(props: {
    party: "groom" | "bride"
}) {
    const [open, setOpen] = React.useState(false);

    const resources = useTextResources().giving.registry;
    const partyResources = resources[props.party];
    const config = useTextConfig().registry[props.party].bankTransfer;

    const notifier = useNotifier();

    return (
        <>
            <button
                className="show-bank-information"
                onClick={() => {
                    setOpen(true);
                    logGivingEvent("show_bank_info_clicked", partyResources.title);
                }}
            >
                {resources.bankAccount.openButton}
            </button>

            <Dialog
                className="bank-account-dialog"
                open={open}
                onClose={
                    () => {
                        setOpen(false);
                        logGivingEvent("bank_info_closed", partyResources.title);
                    }
                }
                fullWidth={false}
            >
                <DialogTitle>{partyResources.dialogTitle}</DialogTitle>

                <DialogContent>
                    <div className="bank-transfer">
                        <div>
                            <span>{resources.bankAccount.bankName}</span>
                            <span>{config.bankName}</span>
                        </div>

                        <div>
                            <span>{resources.bankAccount.accountNumber}</span>
                            <span>{config.accountNumber}</span>
                        </div>

                        <div>
                            <span>{resources.bankAccount.accountOwner}</span>
                            <span>{config.accountName}</span>
                        </div>
                    </div>
                </DialogContent>

                <DialogActions>
                    <CopyToClipboard
                        text={config.accountNumber}
                        onCopy={() => {
                            notifier.success(resources.bankAccount.copiedNotice);
                            logGivingEvent("bank_info_copied", partyResources.title);
                        }}
                    >
                        <Button className="copy">{resources.bankAccount.copyButton}</Button>
                    </CopyToClipboard>

                    <Button className="close"
                        onClick={() => {
                            setOpen(false);
                            logGivingEvent("bank_info_close_clicked", partyResources.title);
                        }}>
                            {resources.bankAccount.closeButton}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}