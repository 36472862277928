import TextResources from "./TextResources";

export const textResourcesEn: TextResources = {
    locale: "en-US",

    pageTitle: "Matthew & Sohyun",

    invitation: {
        title: "Save the Date",
    },

    gallery: {
        title: "Gallery",
        seeMoreButton: "See more",
        instagramButton: "Follow us on Instagram"
    },

    directions: {
        title: "Directions",
        navigation: {
            androidLinkName: "Navigation",
            iosLinkName: "Naver Maps",
            webLinkName: "Open Map",
        },
        drivingDirectionsTitle: "Personal Vehicle",
        subwayDirectionsTitle: "Subway",
        busDirectionsTitle: "Bus",
        seeMoreButton: "See more",
        addressCopied: "Address copied to clipboard."
    },

    giving: {
        registry: {
            title: "Registry",
            introduction: "",
            bankAccount: {
                openButton: "View Bank Account",
                copyButton: "Copy",
                closeButton: "Close",
                copiedNotice: "Account number copied.",
                bankName: "Bank",
                accountNumber: "Number",
                accountOwner: "Owner"
            },
            kakaoPayButton: "Kakao Pay",
            groom: {
                title: "Groom's Information",
                dialogTitle: "Groom's Account"
            },
            bride: {
                title: "Bride's Information",
                dialogTitle: "Bride's Account"
            },
            overseasTitle: "Registry"
        },

        comments: {
            title: "Leave a Message",

            submittedIntro: "Thank you for leaving us a message!",
            commentingDisabled: "Thank you for all your messages! Messaging has been closed.",
            openButton: "Leave another",
            seeMoreButton: "See more of {0} remaining",

            form: {
                authorLabel: "Name",
                textLabel: "Message",
                submitButton: "Submit",
                cancelButton: "Cancel",

                success: "Thank you for leaving us a message!",

                errors: {
                    authorRequired: "Please enter your name.",
                    textRequired: "Please enter your message.",
                    general: "Failed to submit your message. Please try again."
                }
            },

            delete: {
                button: "Delete",
                confirm: "Are you sure you want to delete your message?",
                success: "Your message has been deleted.",
                error: "Failed to delete your message. Please try again."
            }
        }
    }
};